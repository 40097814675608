<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction } from '@/command/netTool'
import { Tooltip } from 'ant-design-vue'

export default {
  name: 'dsplatform',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getDsReportList',
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
      shTypeList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(params = this.params) {
      getAction('/merchantTransaction/page', params).then((e) => {
        this.params = params
        let data = e.data.records
        let arr = []
        for (let i = 0; i < data.length; i++) {
          const ele = data[i]
          const productList = ele.productList
          for (let j = 0; j < productList.length; j++) {
            const pro = productList[j]
            arr.push({ ...ele, ...pro, rowIndex: j == 0 ? productList.length : 0 })
          }
        }
        this.current = e.data['current']
        this.total = e.data['total']
        this.records = arr
      })
    },
    renderSpan(value, row, index) {
      const obj = {
        children: value,
        attrs: {},
      }

      obj.attrs.rowSpan = row.rowIndex

      return obj
    },
    getHeader() {
      return [
        {
          name: '时间',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
          props: {
            allowClear: true,
          },
        },
        {
          name: '商品名称',
          key: 'productName',
          type: 'input',
        },
        {
          name: '商品编号',
          key: 'productId',
          type: 'input',
        },
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '待支付',
              value: '0',
            },
            {
              name: '待发货',
              value: '2',
            },
            {
              name: '待签收',
              value: '3',
            },
            {
              name: '待评价',
              value: '4',
            },
            {
              name: '已完成',
              value: '5',
            },
            {
              name: '已关闭',
              value: '6',
            },
          ],
        },
        {
          name: '商户名称',
          key: 'shopName',
          type: 'input',
        },
        {
          name: '二级商户编号',
          key: 'subMerId',
          type: 'input',
        },
        {
          name: '交易客户号',
          key: 'userId',
          type: 'input',
        },
        {
          name: '手机号',
          key: 'telephone',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          fixed: 'left',
          width: 120,
          title: '交易时间',
          dataIndex: 'createTime',
          customRender: this.renderSpan,
        },
        {
          title: '订单号',
          dataIndex: 'orderSn',
          customRender: this.renderSpan,
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (text, row) => {
            //  0->待支付；2->待发货；3->待签收；4->待评价；5->已完成；6->已关闭
            let status
            if (text == 0) {
              status = '待支付'
            } else if (text == 2) {
              status = '待发货'
            } else if (text == 3) {
              status = '待签收'
            } else if (text == 4) {
              status = '待评价'
            } else if (text == 5) {
              status = '已完成'
            } else if (text == 6) {
              status = '已关闭'
            }
            return this.renderSpan(status, row)
          },
        },
        {
          title: '二级商户编号',
          dataIndex: 'subMerId',
          customRender: this.renderSpan,
        },
        {
          title: '商户名称',
          dataIndex: 'shopName',
          customRender: this.renderSpan,
        },
        {
          title: '商品编号',
          dataIndex: 'productId',
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
        },
        {
          title: '上架状态',
          dataIndex: 'upDown',
          customRender: (text) => {
            if (text == 0) {
              return '上架'
            } else if (text == 1) {
              return '下架'
            } else {
              return '已失效'
            }
          },
        },

        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: this.renderSpan,
        },
        {
          title: '优惠金额',
          dataIndex: 'couponAmount',
          customRender: this.renderSpan,
        },
        {
          title: '实收金额',
          dataIndex: 'payAmount',
          customRender: this.renderSpan,
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          customRender: this.renderSpan,
        },
        {
          title: '交易客户号',
          dataIndex: 'userId',
          customRender: this.renderSpan,
        },
        {
          title: '交易手机号',
          dataIndex: 'telephone',
          customRender: this.renderSpan,
        },
        {
          title: '订单手机号',
          dataIndex: 'receiverPhone',
          customRender: this.renderSpan,
        },
        {
          title: '订单备注',
          dataIndex: 'remark',
          width: 150,
          fixed: 'right',
          customRender: (text, row) => {
            const dom = (
              <div style={{ width: '150px' }}>
                <Tooltip title={text}>
                  <div>{text.length > 10 ? `${text.slice(0, 10)}...` : text}</div>
                </Tooltip>
              </div>
            )

            const obj = {
              children: dom,
              attrs: {},
            }

            obj.attrs.rowSpan = row.rowIndex

            return obj
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const data1 = { ...this.params }
            delete data1.current
            delete data1.size
            delete data1.pageSize
            let values
            Object.keys(data1).map((key, i) => {
              if (data1[key]) {
                if (!values) {
                  values = `?${key}=${data1[key]}`
                } else {
                  values = values + `&${key}=${data1[key]}`
                }
              }
            })

            downloadAction(`/api/merchantTransaction/export${values || ''}`, '商户交易订单流水.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.shTypeList = records.map((d) => d.shType)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        headerTypeData={this.headerTypeData}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
